.sortBreadCrumbWrapper {
	float: left;
}

.sortBreadCrumb {
	background: #fff;
	color: #000;
	display: table-cell;
	font-size: 1.3rem;
	font-weight: 400;
	height: 40px;
	line-height: 2.3rem;
	text-transform: capitalize;
	vertical-align: middle;

	a,
	a:visited,
	a:active {
		color: #000;
		display: inline-block;
		text-decoration: none;
	}

	[class^='lpIcon-'],
	[class^='lpIcon-']:visited,
	[class^='lpIcon-']:active {
		color: #000;
		font-size: 1.15em;
		position: relative;
		top: 2px;
	}

	.rootLink {
		vertical-align: middle;
	}

	.breadCrumbValueWrapper {
		a:not(.breadcrumbRemoveLevel):hover {
			text-decoration: underline;
		}
	}
}
