.sortFilters {
	float: right;
	margin-bottom: 18px;
	text-align: right;

	&.centerSortFilter {
		float: none;
		margin: 20px auto;
		text-align: center;
		width: calc((100%) - (700px));
	}
}

.paginationOuterWrapper {
	margin: 38px 0 0;
}

.paginationWrapper {
	li {
		display: inline-block;
		list-style-type: none;

		&.current {
			a {
				background: #eee;
				font-weight: 500;
			}
		}

		&.first::after {
			content: ' ... ';
			display: inline-block;
			margin-left: 5px;
		}

		&.last::before {
			content: ' ... ';
			display: inline-block;
			margin-right: 5px;
		}

		a {
			border-radius: 15px;
			display: inline-block;
			height: 12px;
			line-height: 12px;
			min-width: 26px;
			padding: 8px 2px;
			text-align: center;
			text-decoration: none;
		}
	}

	&.bottom {
		align-items: center;
		align-items: center;
		display: flex;
		display: flex;
		justify-content: center;
		justify-content: center;
		margin: auto;

		ul {
			display: flex;
			display: flex;
			margin: 0 2em;
		}
	}
}

.paginationRange {
	margin-top: 10px;
	text-align: center;
}
