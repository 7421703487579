@import '../global/style-guide';

.sortSplash {
	position: relative;

	// temporary code to center splash until Marketing completely redesigns this splash section
	.isSemiResponsive & {
		margin-left: auto;
		margin-right: auto;
		width: 960px;
	}
}

.sortSplashContainer {
	background: none repeat scroll 0 0 #fff;
	margin-bottom: -15px;

	&:has(#openBoxSortPageBucket) {
		margin-bottom: -5px;
	}

	&--luxuryLighting {
		margin-bottom: 0;
	}

	&.landingPage {
		padding: 0;
	}

	+ #sortMainCol {
		padding-top: 0;

		.splashDesc {
			margin-bottom: 15px;
		}
	}
}

#openBoxSortPageBucket {
	.searchContainer {
		float: right;
		margin: -20px 0 0 0;

		.searchInput {
			width: 420px;
		}
	}
}

.sortSplashContent {
	height: 240px;
	left: 20px;
	position: absolute;
	top: 30px;
	width: 315px;
	z-index: 1;

	&.white {
		color: #fff;

		h1 {
			color: #fff;
		}
	}

	&.sTroy {
		color: #fff;
		height: 255px;
		left: 30px;
		position: absolute;
		width: 345px;
		z-index: 1;
	}

	&.sLandscapeLighting {
		color: #fff;
		height: 215px;
		left: 30px;
		position: absolute;
		top: 35px;
		width: 315px;
		z-index: 1;
	}

	&.sTrend {
		height: 240px;
		left: 30px;
		position: absolute;
		top: 60px;
		width: 280px;
		z-index: 1;

		h1 {
			font-family: Georgia;
			font-size: 36px;
			font-style: italic;
			font-weight: normal;
			letter-spacing: -1px;
			line-height: 0.85em;
			text-transform: capitalize;
		}
	}

	&.sLightingFixtures {
		height: 215px;
		left: 20px;
		position: absolute;
		top: 35px;
		width: 345px;
		z-index: 1;

		h1 {
			font-size: 32px;
			letter-spacing: -1px;
			line-height: 0.85em;
			text-transform: uppercase;
		}

		p {
			font-size: 20px;
			line-height: 26px;
		}
	}

	&.sMaxim {
		color: #fff;
		height: 265px;
		left: 30px;
		position: absolute;
		top: 5px;
		width: 355px;
		z-index: 1;
	}

	&.sMrKate {
		height: 265px;
		left: 30px;
		position: absolute;
		top: 5px;
		width: 355px;
		z-index: 1;
	}

	h1 {
		font-size: 24px;
		line-height: 25px;

		span {
			font-size: 14px;
			font-weight: normal;
		}

		span:nth-of-type(2) {
			display: block;
			font: normal 14px/16px Arial, Helvetica, sans-serif;
			letter-spacing: 0;
			text-transform: none;
		}
	}

	p {
		clear: both;
		font-size: 14px;
		line-height: 18px;
		margin: 15px 0 0;
	}

	#sbtWatchVideo {
		cursor: pointer;
		display: block;
		padding-top: 17px;
	}
}

.sortPageHeaderWithResult {
	.sortSplashTitleLeft {
		float: unset;
	}

	& > div {
		margin-top: -58px;
	}
}

.sortSplashRightMssg,
.sortSplashRightMssgLong,
.sortSplashRightMssgXLong,
.sortSplashRightMssgShort,
.sortSplashRightMssgXSmall,
.sortSplashRightMssgXXLong {
	float: right;
	font-size: 11px;
	letter-spacing: -0.01em;
	margin-bottom: 13px;
	margin-top: -40px;
	text-align: right;

	span {
		font-size: 10px;
	}

	strong {
		color: #900;
		font-size: 1.4rem;
		font-weight: 500;

		font-weight: 400;
		text-decoration: underline;

		span {
			font-size: 14px;
		}
	}

	.freeShippingSplashTooltip {
		display: inline-block;
		width: auto;

		.freeShippingTooltip {
			&:hover &__trigger {
				z-index: 9002;
			}

			.anchorLink {
				color: #900;
				text-decoration: unset;
			}

			.lpTooltip {
				color: #333;
				font-size: 14px;
				font-weight: normal;
				line-height: 25px;
				text-align: left;
				text-transform: none;
				width: 410px;
				z-index: 10;

				&__inner {
					padding: 20px;
				}
			}
		}
	}
}

.sortSplashRightMssg {
	margin-top: -60px;
	width: 550px;
}

.splashCopyLinkBold {
	font-size: 1.1rem;
	font-weight: 500;
}

.sortSplashRightMssgShort {
	margin-top: -60px;
	width: 525px;
}

.sortSplashRightMssgLong {
	margin-top: -60px;
	width: 590px;
}

.sortSplashRightMssgXSmall {
	margin-top: -60px;
	width: 450px;
}

.sortSplashRightMssgXLong {
	margin-top: -60px;
	width: 600px;
}

.sortSplashRightMssgXXLong {
	margin-top: -60px;
	width: 620px;
}

#bdProdSort .sortSplashContent a {
	color: #fff;
}

#lpContainer .splashBtn,
#lpContainer a.splashBtn {
	background: #c00;
	background: linear-gradient(to bottom, #c00 0%, #900 100%);
	border-radius: 2px;
	bottom: 0;
	color: #fff;
	display: block;
	font-size: 1.4rem;
	font-weight: 500;
	padding: 5px 30px;
	position: absolute;
	text-decoration: none;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);

	&:hover,
	&:focus {
		background: #01c601;
		background: linear-gradient(to bottom, #01c601 0%, #006900 100%);
		color: #fff;
	}

	span {
		border-color: transparent transparent transparent #fff;
		border-style: solid;
		border-width: 4px 0 4px 8px;
		display: inline-block;
		height: 0;
		width: 0;
	}
}

.sortSplashSlider {
	img {
		display: none;
	}
}

.nivoSlider {
	height: 300px;
	position: relative;
	width: 960px;

	img {
		left: 0;
		position: absolute;
		top: 0;
	}
}

.nivoSlider .nivo-imageLink,
.nivo-prevNav,
.nivo-nextNav {
	display: none;
}

.sortSplashTitleMssgWrapper {
	padding: 0 0 20px;

	h1 {
		font-family: var(--fontSerif);
		font-size: 28px;
		font-weight: 400;
		letter-spacing: 0;
		line-height: 25px;
		margin: 0;
	}
}

.sortSplashBucketsWrapper {
	padding: 15px 0 19px;

	&.altCol6,
	&.altCol5,
	&.altCol4 {
		padding: 0 0 8px;

		.sortSplashBuckets {
			margin: 0 0 0 8px;

			div::before {
				display: none;
			}

			&:hover a {
				color: $lpRed;
			}

			img {
				border-radius: 5px;
				display: block;
				margin: 0 auto;
			}
		}

		.alt1 .sortSplashBuckets {
			h2,
			.h2 {
				margin: 15px 0 8px;
			}
		}
	}

	&.altCol6 {
		.sortSplashBuckets:nth-child(6n + 1) {
			margin-left: 0;
		}
	}

	&.altCol5 {
		.sortSplashBuckets {
			width: 174px;

			&:nth-child(5n + 1) {
				margin-left: 0;
			}
		}
	}

	&.altCol4 {
		.sortSplashBuckets {
			width: 220px;

			&:nth-child(4n + 1) {
				margin-left: 0;
			}
		}
	}

	&.bucketsPerRow2,
	&.bucketsPerRow3,
	&.bucketsPerRow4,
	&.bucketsPerRow5,
	&.bucketsPerRow6,
	&.bucketsPerRow10 {
		--sortSplashBuckets-vert-margin: 5px;
		padding: 0 0 8px;

		.alt1 {
			.sortSplashBuckets {
				div {
					&::before {
						display: none;
					}

					&:hover a {
						color: $lpRed;
					}
				}

				h2,
				.h2 {
					margin: 15px 0 8px;
				}

				img {
					border: 1px solid #ccc;
					border-radius: 5px;
				}
			}
		}

		&.bucketsPerRowWithFlex {
			.sortSplashBucketsContainer {
				display: flex;
				justify-content: center;

				.isSemiResponsive & {
					justify-content: space-between;
				}
			}

			.alt1 {
				.sortSplashBuckets {
					margin: 0 var(--sortSplashBuckets-vert-margin);
				}
			}

			.alt2 {
				margin-bottom: 5px;

				h2 {
					font-weight: 500;
					margin: 15px 0 0;
				}

				img {
					height: auto;
					width: 164px;
				}

				.sortSplashBuckets > div::before {
					display: none;
				}
			}

			img {
				border: 0;
			}
		}

		&.bucketsPerRowWithFlexNoBorder {
			.sortSplashBucketsContainer {
				display: flex;
				justify-content: center;

				.isSemiResponsive & {
					justify-content: space-between;
				}
			}

			.sortSplashBuckets h2 {
				font-weight: 500;
			}

			.alt1 {
				.sortSplashBuckets img {
					border: none;
				}
			}
		}
	}

	&.bucketsPerRow2,
	&.bucketsPerRow3 {
		text-align: center;

		.sortSplashBuckets {
			background-color: #f1f1f1;
			border: 1px solid #ccc;
			display: inline-block;
			float: none;
			height: 125px;
			margin: 0 3px;
			padding: 6px;
			text-align: left;

			h2 {
				font-weight: 500;
				height: 2em;
				margin: 8px 0;
				text-align: left;
				text-transform: uppercase;
			}

			p {
				font-size: 0.95em;
				height: 75px;
				line-height: 1.3em;

				&.smallerFont {
					font-size: 0.9em;
				}
			}

			div {
				&::before {
					box-shadow: none;
					content: none;
				}

				img {
					float: left;
					margin: -6px 10px 0 -6px;
					vertical-align: top;
				}

				> div {
					float: left;
					height: 120px;
					width: 50%;
				}

				.h2 {
					float: none;
					font-weight: 500;
					height: 1em;
					text-align: left;
					width: 100%;
				}
			}
		}
	}

	&.bucketsPerRow2 {
		.sortSplashBuckets {
			margin: 0 3px;
			padding: 15px 15px 0;
			width: 41%;

			div img {
				margin: -15px 10px 0 -15px;
			}
		}
	}

	&.bucketsPerRow3 {
		.sortSplashBuckets {
			max-width: 265px;
		}
	}

	&.bucketsPerRow4 {
		.sortSplashBuckets {
			margin: 0 12px;
		}

		.alt1 {
			.sortSplashBuckets {
				margin: 0 11px;
			}
		}
	}

	&.bucketsPerRow5 {
		.sortSplashBuckets {
			margin: 0 10px;
		}

		.alt1 {
			.sortSplashBuckets {
				margin: 0 9px;
			}
		}
	}

	&.bucketsPerRow6 {
		.sortSplashBuckets {
			margin: 0 3px;
		}
	}

	&.bucketsPerRow10 {
		padding-bottom: 0;

		.sortSplashBucketsContainer {
			clear: both;
		}

		.sortSplashBuckets {
			--sortSplashBuckets-vert-margin: 15px;
			max-width: calc((100% - (10 * var(--sortSplashBuckets-vert-margin) * 2)) / 10);

			@media (max-width: 1147px) {
				--sortSplashBuckets-vert-margin: 10px;
			}

			@media (max-width: 1047px) {
				--sortSplashBuckets-vert-margin: 5px;

				.h2 {
					font-size: 1.2rem;
				}
			}

			.h2 {
				line-height: 2rem;
			}
		}
	}

	&__title {
		font-size: 4.2rem;
		margin: 53px 10px 20px;
		text-align: center;
		text-transform: uppercase;
	}

	&_colorSwatches {
		margin-bottom: 50px;
	}

	&--flexBucketRow {
		padding: 0;

		.sortSplashBucketsContainer {
			.isSemiResponsive & {
				margin-bottom: 60px;
				max-width: none;

				&.sortSplashBucketsContainer--margin-s {
					margin-bottom: 15px;
				}
			}
		}

		.sortSplashBuckets {
			&__img {
				display: block;

				@media (max-width: 1300px) {
					height: 235px;
					width: 235px;
				}

				@media (max-width: 1200px) {
					height: 200px;
					width: 200px;
				}
			}

			&__title {
				font-size: 2rem;
				text-align: center;
				text-transform: uppercase;
			}

			&__title.splashBucketsTitle {
				text-transform: capitalize;
			}
		}
	}

	h1 {
		font-family: var(--fontSerif);
		font-size: 28px;
		font-weight: 400;
		letter-spacing: 0;
		line-height: 25px;
		margin: 0;
		text-align: left;

		@media (max-width: 1050px) {
			font-size: 27px;
		}
	}

	.callout1 {
		font-size: 14px;
	}

	.callout2 {
		font-size: 18px;
	}

	&.bucketsPerRowWithFlexNoBorder.bucketsPerRow6 {
		.sortSplashTitleLeft + .result {
			margin-bottom: 0;
		}

		.sortSplashRightMssgLong {
			margin-top: -46px;
		}
	}
}

.sortSplashTitleMssgWrapper,
.sortSplashBucketsWrapper {
	&:has([class*='sortSplashRightMssg']) {
		padding: 0 0 8px;
		position: relative;

		h1 {
			line-height: 1.1;
			width: 50%;
		}

		[class*='sortSplashRightMssg'] {
			margin: 0;
			max-width: calc(50% - 20px);
			position: absolute;
			right: 0;
			top: 0;
		}

		.clear {
			display: none;
		}
	}
}

.sortSplashBucketsContainer,
.dy_unit,
/* Temporary fix: added dynamic yield selectors for when DY adds an extra div around buckets (bug on DY side) */
.dyother,
/* Using multiple DY selectors, because not sure which one will be available for certain tests */
.dyMonitor {
	margin: 0 -8px;

	&.eightBuckets {
		margin: 0 -10px;

		.sortSplashBuckets {
			margin: 0 10px;
			width: 216px;
		}
	}

	.isSemiResponsive & {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 0;
		width: 100%;

		&.sortSplashBucketsTableContainer {
			justify-content: space-evenly;
		}

		.sortSplashBuckets {
			margin: 0;
		}

		br {
			display: none;
		}
	}
}

.sortSplashBucketsContainer {
	.isSemiResponsive & {
		margin: 0 auto;
		max-width: 1280px;
		width: auto;
	}
}

.sortSplashBuckets {
	cursor: pointer;
	float: left;
	margin: 0 8px;

	/* 8 items */
	.sortSplashBucketsContainer &:first-child:nth-last-of-type(8),
	.sortSplashBucketsContainer &:first-child:nth-last-of-type(8) ~ div {
		flex: 1 0 25%;
		margin: 0 40px;
		max-width: 216px;

		@media screen and (max-width: 1280px) {
			margin: 0 25px;
		}

		@media screen and (max-width: 1140px) {
			margin: 0 10px;
		}
	}

	/* 10 items */
	.sortSplashBucketsContainer &:first-child:nth-last-of-type(10),
	.sortSplashBucketsContainer &:first-child:nth-last-of-type(10) ~ div {
		.sortSplashBucketsWrapper:not(.bucketsPerRow10) & {
			flex: 1 0 20%;
			margin: 0 30px;
			max-width: 172px;

			@media screen and (max-width: 1280px) {
				margin: 0 20px;
			}

			@media screen and (max-width: 1140px) {
				margin: 0 10px;
			}
		}
	}

	/* 12 items */
	.sortSplashBucketsContainer &:first-child:nth-last-of-type(12),
	.sortSplashBucketsContainer &:first-child:nth-last-of-type(12) ~ div,
	.sortSplashBucketsContainer &:first-child:nth-last-of-type(14),
	.sortSplashBucketsContainer &:first-child:nth-last-of-type(14) ~ div {
		flex: 1 0 16.6%;
		margin: 0 20px;
		max-width: 155px;

		@media screen and (max-width: 1280px) {
			margin: 0 10px;
		}

		@media screen and (max-width: 1140px) {
			margin: 0;
		}

		&.sortToggleCollapsed {
			flex: 1 0 100%;
		}
	}

	/* 14 items */
	.sortSplashBucketsContainer &:first-child:nth-last-of-type(14),
	.sortSplashBucketsContainer &:first-child:nth-last-of-type(14) ~ div {
		margin: 0 10px;

		@media screen and (max-width: 1320px) {
			margin: 0 3px;
		}

		@media screen and (max-width: 1200px) {
			margin: 0;
		}
	}

	div {
		position: relative;

		&::before {
			content: '';
			height: 100%;
			position: absolute;
			width: 100%;
		}

		img {
			display: block;
		}
	}

	h2,
	.h2 {
		font-family: var(--fontHeading);
		font-size: 14px;
		font-weight: normal;
		letter-spacing: 0;
		margin: 8px 0 15px;
		text-align: center;

		.lrgText & {
			font-size: 15px;
			font-weight: 500;
		}

		.xLrgText & {
			font-size: 18px;
			font-weight: 500;
		}
	}

	a,
	a:visited {
		color: #333;
		text-decoration: none;
	}

	a:hover {
		color: $lpRed;
	}

	p {
		font-size: 11px;
	}
}

.sortSplashLayoutAlt1 {
	margin: 0;

	.sortSplashContent {
		color: #fff;
		height: 215px;
		left: 30px;
		position: absolute;
		top: 35px;
		width: 315px;
		z-index: 1;
	}

	h1 {
		font-size: 35px;
		font-weight: 500;
		line-height: 0.85em;
		text-transform: none;
	}

	p {
		font-size: 20px;
		line-height: 25px;
		margin: 15px 0 0;
	}
}

#lpContainer .sortSplashLayoutAlt1 .splashBtn {
	padding: 6px 15px;
}

.sortSplashLayoutAlt2 {
	margin: 0;

	h1 {
		text-transform: none;
	}
}

#lpContainer .sortSplashLayoutAlt2 .splashBtn {
	padding: 6px 15px;
}

#bdLampShades {
	margin: 0;

	.sortSplashContent {
		color: #000;
		height: 230px;
		left: 30px;
		position: absolute;
		top: 35px;
		width: 315px;
		z-index: 1;

		p {
			font-size: 22px;
			line-height: 24px;
		}
	}

	h1 {
		font-size: 35px;
		font-weight: 500;
		line-height: 0.85em;
		text-transform: none;
	}

	h2 {
		font-size: 14px;
		font-weight: 500;
		margin: 15px 0;
		text-transform: uppercase;
	}

	h3 {
		color: #fff;
	}

	p {
		font-size: 20px;
		line-height: 25px;
		margin: 15px 0 0;
	}

	li {
		color: #6e6e6e;
	}
}

.sortSplashContainer + #sortMainCol {
	padding-top: 0;
}

.sortSplashContainer + #sortMainCol .splashDesc {
	margin-bottom: 15px;
}

.sortToggle {
	text-align: right;

	.calloutBtn.collapsibleToggle {
		display: inline-block;
		position: relative;
		top: 20px;
	}

	.caret {
		margin-left: 4px;
	}
}

.sortToggleCollapsed {
	display: flex;
}

.collapsed .sortToggleCollapsed {
	display: none;
}

.sortLandingDescription2 {
	.sparkle-tips-section.mobile {
		display: none;
	}
}

.sortSaleHeader {
	margin: 0 auto;
	max-width: 1280px;

	.sortSplashBtn {
		align-items: center;
		display: grid;
		margin: 0 auto;
		padding-top: 10px;
		width: 70%;

		&.sortSplashHomeSale {
			max-width: 860px;
			width: unset;
		}
	}

	hr {
		&:first-child {
			border: 0;
			border-top: 1px solid black;
			margin: 17px 0 10px;
			width: 38%;
		}

		&:last-child {
			border: 0;
			border-top: 1px solid black;
			margin: 17px 0 10px 62%;
			margin-top: -70px;
			width: 38%;
		}
	}

	.banner {
		cursor: pointer;
		margin: 0 auto 24px;
		width: fit-content;
	}

	img {
		display: block;
		margin: 0 auto;

		&.mobile {
			display: none;
		}
	}

	strong {
		&.desktop {
			display: block;
		}

		&.mobile {
			display: none;
		}
	}

	.sortHomeDecor.mobile {
		display: none;
	}

	.saleSearchBox {
		align-items: center;
		background-color: #f1f1f1;
		column-gap: 4rem;
		display: flex;
		justify-content: center;
		margin-bottom: 6rem;
		margin-top: 10px;
		padding: 28px 0;

		.searchContainer {
			margin: 1px 0 0 20px;

			.searchInputResponsive {
				background: #fff;

				@media (max-width: 1024px) {
					width: 287px;
				}
			}
		}

		.searchArrowBtn {
			background: #fff;

			&::before {
				top: 11px;
			}
		}

		p {
			color: #000;
			font-size: 18px;
			font-weight: 500;
			margin: 0;
		}

		span {
			font-weight: normal;
			padding-left: 6px;
		}
	}

	.sortSaleBanner.mobile {
		display: none;
	}

	.saleSearchBox.mobile {
		display: none;
	}

	&--wide {
		.banner img {
			display: block;
			margin: auto;
		}

		.saleCategories__row {
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			margin-bottom: 30px;

			figure {
				flex-basis: 220px;
				margin: 0;

				img {
					height: auto;
					width: 100%;
				}
			}

			a {
				display: block;
				text-align: center;
			}
		}

		p {
			margin-bottom: 30px;
			text-align: center;

			a {
				text-decoration: underline;
			}
		}

		@media screen and (max-width: 1024px) {
			.saleCategories__row {
				justify-content: center;

				figure {
					flex-basis: 190px;
					margin-right: 32px;

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
	}

	&:not(.sortSaleHeader--wide) {
		figure {
			float: left;
			margin: 0 0 26px 32px;
			width: 198px;

			&:nth-child(4n + 1) {
				margin-left: -10px;
			}

			&:nth-child(4n) {
				margin-right: -10px;
			}
		}

		p {
			clear: left;
			margin: 10px 0 30px;
			text-align: center;

			a {
				text-decoration: underline;
			}
		}
	}

	figcaption {
		font-family: var(--fontHeading);
		font-size: 16px;
		letter-spacing: -0.75px;
		text-align: center;
		white-space: nowrap;
	}

	a {
		text-decoration: none;
	}
}

.sortSplashBucketsContainer {
	margin: 0 auto;
	max-width: 1280px;
}

// ambassadors legacy
#brandAmbassadors {
	.sortSplashContent {
		height: 337px;
		text-align: center;
		width: 337px;
	}

	h1 {
		font-size: 3.5rem;
		margin: 10px 0;

		.bottom {
			display: block;
			font-size: inherit;
			font-weight: 500;
			margin: 10px 0;
		}
	}

	p {
		font-size: 1.26rem;
		margin: 0 29px 10px;
		text-align: left;

		.newLine {
			white-space: nowrap;
		}
	}

	.subheading {
		font-size: 1.82rem;
		font-weight: 500;
		margin: 17px 0 15px;
		text-align: center;
	}

	.footerText {
		bottom: 15px;
		font-size: 1.45rem;
		font-weight: 500;
		margin: 0;
		position: absolute;
		text-align: center;
		width: 100%;
	}
}

// wide format splash
.wideResponsiveSplash {
	align-items: center;
	background-position: center;
	background-size: cover;
	display: flex;
	height: 395px;
	position: relative;
	width: 100%;
	z-index: 1;

	// specific to ambassador trends
	&--dennis {
		background-image: url('/images/landing-pages/trend-splashes/amabassadors/lori-dennis-1440.jpg');
	}

	&--farrell {
		background-image: url('/images/landing-pages/trend-splashes/amabassadors/jennifer-farrell-1440.jpg');
	}

	&--hawley {
		background-image: url('/images/landing-pages/trend-splashes/amabassadors/joanna-hawley-1440.jpg');
	}

	&--merhi {
		background-image: url('/images/landing-pages/trend-splashes/amabassadors/farah-merhi-1440.jpg');
	}

	&--wood {
		background-image: url('/images/landing-pages/trend-splashes/amabassadors/karri-anne-1440.jpg');
	}

	&--britto {
		background-image: url('/images/landing-pages/trend-splashes/amabassadors/britto-1440.jpg');
	}

	&--stJude {
		background-image: url('/images/st-jude/LandingPage-Winter-St-Jude.jpg');
	}

	// specific to non-trends
	&--landscapekits {
		background-image: url('/images/landing-pages/landscape-lighting-kits/landscape-kits-1440.jpg');
	}

	&--pooltablelights {
		background-image: url('/images/landing-pages/pool-table-chandeliers/pool-table-lights-1440.jpg');
	}

	// specific to general trends
	&--cottage-living {
		background-image: url('/images/landing-pages/trend-splashes/wide/cottage-living.jpg');
	}

	&--eclectic-chic {
		background-image: url('/images/landing-pages/trend-splashes/wide/eclectic-chic.jpg');
	}

	&--french {
		background-image: url('/images/landing-pages/trend-splashes/wide/french-refined.jpg');
	}

	&--global-mix {
		background-image: url('/images/landing-pages/trend-splashes/wide/global-mix.jpg');
	}

	&--industrial-chic {
		background-image: url('/images/landing-pages/trend-splashes/wide/industrial-chic.jpg');
	}

	&--luxe-living {
		background-image: url('/images/landing-pages/trend-splashes/wide/luxe-living.jpg');
	}

	&--century-modern {
		background-image: url('/images/landing-pages/trend-splashes/wide/mid-century-modern.jpg');
	}

	&--modern-farmhouse {
		background-image: url('/images/landing-pages/trend-splashes/wide/modern-farmhouse.jpg');
	}

	&--modern-mix {
		background-image: url('/images/landing-pages/trend-splashes/wide/modern-mix.jpg');
	}

	&--new-traditional {
		background-image: url('/images/landing-pages/trend-splashes/wide/new-traditional.jpg');
	}

	&--outdoor-lifestyle {
		background-image: url('/images/landing-pages/trend-splashes/wide/outdoor-lifestyle.jpg');
	}

	&--craftsman {
		background-image: url('/images/landing-pages/trend-splashes/wide/trend-crafstman.jpg');
	}

	&--tropical-casual {
		background-image: url('/images/landing-pages/trend-splashes/wide/tropical-casual.jpg');
	}

	&--vintage-charm {
		background-image: url('/images/landing-pages/trend-splashes/wide/vintage-charm.jpg');
	}

	// add any specific styles here
	&--landscapekits,
	&--pooltablelights {
		.wideResponsiveSplash__heading {
			font-size: 30px;
			margin-bottom: 5px;

			span:last-child {
				display: block;
				font-size: 14px;
			}
		}
	}

	&--pooltablelights {
		.wideResponsiveSplash__heading {
			font-family: var(--fontSerif);
			font-size: 34px;
			font-weight: 400;
			letter-spacing: 0;
			text-transform: none;
		}
	}

	&--grayText {
		color: #333;
	}

	/*&--stJude {
		.wideResponsiveSplash__heading {
			span:last-child {
				display: none;
			}
		}
	}*/
	&--video-link {
		.wideResponsiveSplash__overlay {
			padding: 35px 30px 10px;
		}

		.wideResponsiveSplash__watch-video {
			margin-top: 1em;
		}
	}

	&--stJude {
		.wideResponsiveSplash__overlay {
			padding: 15px 30px;
		}

		.wideResponsiveSplash__heading {
			margin-bottom: 15px;

			span:last-child {
				display: none;
			}
		}
	}

	&--britto {
		.wideResponsiveSplash__heading {
			margin-bottom: 5px;

			span:last-child {
				display: none;
			}
		}
	}

	&--century-modern,
	&--modern-farmhouse,
	&--craftsman,
	&--industrial-chic {
		.wideResponsiveSplash__overlay {
			width: 525px;
		}
	}

	&--outdoor-lifestyle {
		.wideResponsiveSplash__overlay {
			width: 575px;
		}
	}

	&--stJude {
		.wideResponsiveSplash__container {
			padding-left: 4%;
		}
	}

	&__container {
		align-items: center;
		display: flex;
		margin: auto;
		padding-left: 8%;
		width: 100%;

		@media only screen and (max-width: 960px) {
			padding-left: 3%;
		}
	}

	&__overlay {
		background: rgba(255, 255, 255, 0.8);
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		font-family: var(--fontHeading);
		justify-content: center;
		padding: 35px 30px;
		text-align: center;
		width: 425px;
	}

	&__heading {
		font-size: 34px;
		font-weight: 400;
		letter-spacing: 0.02em;
		margin-bottom: 0;
		text-transform: uppercase;
		white-space: nowrap;

		&--alt {
			margin-bottom: 5px;
		}
	}

	&__subheading {
		font-size: 18px;

		&--uppercase {
			text-transform: uppercase;
		}
	}

	&__subheading,
	&__horizontal-line {
		margin: 3px 0 30px;
		position: relative;
		z-index: 1;

		&::after {
			background: #111;
			content: '';
			display: block;
			height: 1px;
			left: 50%;
			position: absolute;
			top: calc(100% + 15px);
			transform: translateX(-50%);
			width: 15%;
		}
	}

	&__text {
		&:not(:last-child) {
			margin-bottom: 0;
		}
	}

	&__quote {
		font-style: italic;
		margin-bottom: 0;
		margin-top: 1em;
	}
}

// coupon savings splash
.instantCouponSplash {
	$couponColorRed: #c00000;

	border: 8px dashed #ccc;
	font-family: var(--fontHeading);
	line-height: 1.2;
	margin-bottom: 4rem;
	padding: 3rem 0;
	text-align: center;

	&__h1 {
		color: $couponColorRed;
		font-size: 6rem;
		margin-bottom: 1.1rem;
	}

	&__p1 {
		font-size: 2rem;
		font-weight: 500;
	}

	&__p2 {
		font-size: 1.8rem;
	}

	&__code {
		color: $couponColorRed;
		text-transform: uppercase;
	}

	@media screen and (max-width: 1280px) {
		&__h1 {
			font-size: 4.8rem;
		}

		&__p1 {
			font-size: 1.6rem;
		}

		&__p2 {
			font-size: 1.4rem;
		}
	}
}

.sortSplashTitleMssgWrapperNoPadding {
	h1 {
		font-size: 24px;
		line-height: 25px;
		margin: 0 0 12px;
	}
}

.sortSplashBanner {
	margin-top: -15px;
	position: relative;

	&__text {
		background-color: rgba(255, 255, 255, 0.7);
		box-sizing: border-box;
		height: 100%;
		left: 0;
		max-width: 445px;
		padding: 4.6% 3.06% 0;
		position: absolute;
		top: 0;
		width: 44%;

		h1 {
			font: 600 3.8rem/1.2em 'ChronicleDeckRoman', Baskerville, Palatino, 'Times New Roman', serif;
			letter-spacing: -1.12px;
			margin-bottom: 3px;
			text-transform: uppercase;
		}

		&.tableLamps h1 {
			width: 320px;
		}

		p {
			font-size: 1.8rem;
			letter-spacing: 0;
			line-height: 1.1em;

			@media (max-device-width: 768px) {
				font-size: 1.6rem;
			}
		}
	}

	&__img {
		display: block;
		height: auto;
		max-width: 100%;
	}
}

.sortImgCenter {
	display: block;
	margin-bottom: 20px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	width: 30%;
}

.sortBucketImgText {
	font-size: 1.8rem;
	margin: 1rem 0 0;
	text-align: center;
	text-transform: uppercase;
}

.categoryContainer {
	display: grid;
	gap: 44px 4%;
	grid-template-columns: repeat(5, 16.8%);
	margin: 44px auto;
	max-width: 1280px;
}

.categoryLink {
	text-decoration: none;
}

.categoryTitle {
	font-size: 16px;
	font-weight: 500;
	text-align: center;
	white-space: nowrap;

	@media (max-width: 1280px) {
		font-size: 14px;
	}
}

.shopAllOpenBoxContainer {
	margin-bottom: 40px;
	text-align: center;
}

.mobile-openBoxSort {
	display: none;
}

.splashContainerWrapper {
	position: relative;
}

.splashContainer {
	background: url(/images/open-box/OpenBox-Splash.jpg) no-repeat center center;
	display: block;
	height: 312px;
	position: relative;
	text-decoration: none;
	width: 100%;

	&__link {
		display: block;
		height: 100%;
	}

	&__logoWrapper {
		align-items: center;
		display: flex;
		flex-direction: column;
		left: 50px;
		position: absolute;
		top: 50px;

		@media screen and (min-width: 1440px) {
			left: 100px;
		}

		@media screen and (max-width: 1280px) {
			left: 0;
		}

		.searchContainer {
			margin-top: 12px;
		}

		.searchArrowBtn {
			background: #fff;
		}

		.width57 {
			width: 57%;
		}

		.width66 {
			width: 66%;
		}
	}

	&__savingsWrapper {
		text-align: center;
	}

	&__calloutWrapper {
		font-size: 2rem;
	}

	&__description {
		font-size: 1.6rem;
	}

	&__saveBig {
		font-weight: 500;
	}

	&:hover {
		color: #000;
	}
}

.trend_katja,
.trend_jennifer-farrell {
	&.trend_katja-splash-banner--background,
	&.trend_jennifer-farrell-splash-banner--background {
		align-items: center;
		display: flex;
		margin-bottom: 60px;
		position: relative;

		.katja-phone {
			display: none;
		}
	}

	&-splash-banner--overlay {
		background-color: rgba(#fff, 0.9);
		display: flex;
		flex-basis: 460px;
		flex-direction: column;
		height: 58%;
		justify-content: center;
		margin-left: 60px;
		max-width: 460px;
		padding: 35px;
		position: absolute;
		text-align: center;

		h1 {
			color: #555;
			font-size: 34px;
			font-weight: 400;
			margin-bottom: 30px;
			opacity: 0.8;

			& > br {
				display: none;
			}

			&.trend_katja-splash-title {
				color: #707070;

				& > br {
					display: block;
				}

				& + p {
					color: #707070;
				}
			}

			span {
				display: block;
				font-size: 22px;
				font-weight: 100;
				margin-top: 7px;
				position: relative;
				text-transform: uppercase;

				br {
					display: none;
				}

				&::before {
					background: rgba(#000, 0.8);
					bottom: -15px;
					content: '';
					height: 2px;
					left: 50%;
					position: absolute;
					transform: translateX(-50%);
					width: 50px;
				}
			}
		}

		p {
			font-size: 20px;
			font-weight: 100;
			opacity: 0.8;
		}
	}
}

.trend_katja-splash-banner--overlay {
	padding: 80px;

	h1.trend_katja-splash-title.katja-desktop {
		font-family: var(--fontSerif);
		font-size: 38px;
		letter-spacing: 0;
	}

	@media only screen and (min-width: 1701px) {
		height: 35%;
		padding: 58px 75px;

		h1.trend_katja-splash-title.katja-desktop {
			line-height: normal;
		}
	}

	@media only screen and (max-width: 1100px) {
		height: 81%;
		padding: 15px;

		h1.trend_katja-splash-title.katja-desktop {
			font-size: 34px;
			line-height: normal;
			margin-bottom: 10px;
		}
	}
}

.katja-box,
.jennifer-farrell-box {
	background: #f4f4f4;
	box-sizing: border-box;
	display: flex;
	flex-wrap: nowrap;
	margin: 0 50px 50px;
	// max-width: 1100px;
	padding: 35px;

	.im-video {
		margin: 3% 0 4%;

		@media (max-width: 1366px) and (orientation: landscape) {
			margin-top: 6%;
		}
	}

	.decor-video-container {
		cursor: pointer;
		display: flex;
		height: 100%;
		justify-content: center;
		position: relative;

		.lpIcon-video {
			background: #fff;
			border-radius: 50%;
			font-size: 60px;
			position: absolute;
			top: calc(50% - 30px);
		}

		img {
			width: 100%;
		}
	}

	&-img {
		flex-basis: 30%;
		margin-right: 20px;
		max-width: 30%;

		&.jennifer-farrell-box-video {
			flex-basis: 45%;
			max-width: 45%;
		}
	}

	.katja-phone,
	.katja-mobile,
	.jennifer-mobile {
		display: none;
	}

	&-content {
		align-items: center;
		display: inline-flex;
		flex-basis: 50%;
		margin: auto;
		max-width: 50%;
		text-align: center;

		&.jennifer-farrell-box-video-content {
			flex-basis: 55%;
			max-width: 55%;
		}

		p {
			color: #707070;
			font-size: min(max(2.2vw, 2.4rem), 3rem);
			font-weight: 400;
			margin: 0;

			span {
				display: block;
				font-size: 20px;
				font-weight: 600;
				margin-top: 10px;
				opacity: 0.9;
				text-transform: uppercase;
			}
		}
	}

	.katja-box-content {
		flex-basis: 55%;
		max-width: 55%;

		p {
			color: #707070;
		}
	}
}

.jenniferLandingContainer {
	border-top: 2px solid #ccc;
	display: flex;
	flex-wrap: wrap;
	margin-top: 10px;
	padding: 60px 0 0;

	div {
		box-sizing: border-box;
		padding: 0 15px;

		&:first-child {
			flex-basis: 40%;
			max-width: max-content;

			h2.splashTitle {
				font-size: 2.8rem;
				margin: 20px 0;
			}

			p {
				font-size: 18px;
				text-align: left;
			}

			.jennifer-mobile {
				display: none;
			}
		}

		&:last-child {
			display: flex;
			flex-basis: 60%;
			flex-wrap: wrap;
			max-width: max-content;

			div {
				display: block;
				flex-basis: 50%;
				max-width: auto;
				padding: 0 10px;

				@media only screen and (min-width: 1400px) {
					padding: 0 15px;
				}
			}
		}
	}
}

.sortSaleHeader--wide .sortMoreYouMayLike p.jsCertonaTitle {
	text-align: left;
}

.sortPageHeaderFlex {
	align-items: center;
	display: flex;
	justify-content: space-between;

	.sortPageH1Tag {
		flex-grow: 1;
	}

	.sortSplashRightMssg {
		flex-shrink: 1;
		margin: 0 0 0 40px;
		width: auto;

		&--large {
			font-size: 1.4rem;
		}
	}

	+ .result {
		color: #000;
		display: block;
		margin: 3px 0 20px;
	}
}
