@import '../global/style-guide.scss';
@import '../global/utilities';

$sortResultContainerWidth: 274px;

#bdProdDetail {
	&.sfpclass #lpContainer {
		@include responsiveWidthLayout;
	}
}

.sfpclass {
	#divProductDetail-breadCrumb {
		margin: 0 0 1.5rem;
	}
}

.promoCouponCode {
	background: #f1f1f1;
	color: #333;
	display: block;
	font-size: 1.6rem;
	font-weight: normal;
	line-height: 1.5;
	margin: 10px 0;
	padding: 10px 20px;
	width: fit-content;

	@media (max-width: 390px) {
		padding: 10px 14px;
	}

	@media (max-width: 375px) {
		font-size: 1.5rem;
	}

	@media (max-width: 360px) {
		padding: 10px 8px;
	}

	&__highlight {
		color: #2b3064;
	}

	#paypalLaterWidget + & {
		margin-top: -8px;
	}

	.priceInfo + & {
		margin-top: 14px;
	}

	.priceInfo:not(:has(li)) + & {
		margin-top: 7px;
	}

	&__note {
		color: #555;
		font-size: 1.2rem;
	}
}

#qlProdInfoCol {
	width: 100%;

	.plaActions {
		display: flex;
		margin-top: 10px;

		.priceDiv {
			margin-right: 20px;
			width: 55%;

			@media (max-width: 1279px) {
				width: 86%;
			}
		}

		.atcDiv {
			width: 55%;

			@media (min-width: 1280px) {
				width: 50%;
			}
		}
	}

	.shippingWrapperQL {
		margin-left: 0;
	}

	.viewDetailsParent {
		flex: 1;
		flex-basis: 100%;
		margin-left: 62px;
		text-align: center;
		text-transform: uppercase;

		@media (min-width: 1280px) {
			margin-left: 66px;
			min-width: 200px;
		}
	}

	.viewDetailsMinQty {
		margin-left: 0;
	}

	#qlViewDetails {
		font-size: 1.54rem;
	}

	#pdAddToCart,
	#pdSoldOut {
		flex-grow: 1;
		margin-left: 0;
		margin-right: 0;
		min-width: 200px;

		@media (max-width: 1279px) {
			min-width: 100px;
			width: 100px;
		}
	}

	.shippingWrapperQL {
		display: block;
		margin: 12px 0;
	}

	#pnlCartButtonNormal {
		min-width: 100%;
	}

	.addToCartContainer {
		margin-top: -8px;
		width: 50%;
	}

	.downrodLpSelect {
		margin-bottom: 25px;
	}

	#pdSoldOut {
		margin-left: 66px;
	}

	#pnlPleaseCallCustomerService {
		.qlViewDetailsBtn {
			padding: 0;
		}
	}
}

#qlBkg {
	background: #fff;
	display: flex;
	padding: 0;

	@media (min-width: 1440px) {
		padding: 0 4rem;
	}

	.addToWishList {
		padding: 7px 0 0;
	}

	#pnlProductPrice {
		display: block;
		margin: 1rem 0 0.5rem;
	}

	.plaSoldOutLabel ~ #pnlProductPrice {
		margin-top: 1px;

		.tradePriceLabel {
			margin: -6px 0 7px;
		}
	}

	.pdSummaryTeaser {
		padding-bottom: 1rem;
		padding-top: 2.5rem;

		.fullPage & {
			padding-top: 0.9rem;
		}

		&__reviewCount {
			margin: 0.4rem 0 0 0.5rem;
		}
	}
}

#qlProdImg {
	border: 1px solid #ccc;
	float: left;
	padding: 9px;
	text-align: center;
	width: 302px;
}

.plaLayout--soldOut {
	.priceInfo {
		display: none;
	}

	.pdPleaseCall {
		margin-top: 10px;
	}

	.plaSoldOutLabel {
		font-weight: 500;
		margin: 8px 0 7px;
		text-transform: uppercase;
	}
}

#artShadeLinks a {
	float: right;

	&:first-child {
		float: left;
	}
}

.pdSubInfo {
	margin-top: 10px;

	.warning {
		clear: both;
		display: block;
		padding-top: 10px;
		text-align: left;
	}
}

#pnlCartButtonNormal {
	margin: 10px 0 15px;
	min-width: 440px;
}

#qlProdContent {
	float: right;
	height: auto;
	line-height: 1.2em;

	.pricingCalloutContainer {
		margin-bottom: 0;
	}

	.pdAssetActions {
		justify-content: flex-start;
	}

	&.multiproduct {
		.pdAssetActions {
			margin-top: 10px;
		}
	}

	.qlTitleWrapper {
		margin-bottom: -6px;

		&--noReviews {
			margin-bottom: 2.5rem;
		}

		.qlTitleCallout {
			color: #900;
			font-size: 24px;
			font-weight: 500;
		}

		h1 {
			--productTitleFontSize: 3rem;
			display: inline;
			font: 500 var(--productTitleFontSize) / 1.2 var(--fontSerif);
			letter-spacing: -0.05rem;

			.fullPage & {
				--productTitleFontSize: 2.6rem;
			}

			a {
				text-decoration: none;
			}
		}
	}

	p {
		line-height: 19px;
	}

	.pdHighlight {
		display: inline-block;
		gap: 5px;
	}

	.qtyLeft {
		display: block;
	}

	.qlTitleSku {
		font-weight: 500;
	}

	.pdScrollableHeader,
	#pdGicleeShadeInfo,
	#pdScrollableInfo {
		margin-left: 26px;
		width: 325px;
	}

	#pnlPleaseCallCustomerService p {
		text-align: left;
	}

	#ProductPrice_lblFreeShipping {
		font-size: 14px;
		font-weight: 500;
		text-transform: uppercase;
	}

	#ProductPrice_lblFreeShipping span {
		color: #2e6b96;
	}

	#pdOtherOptionsTitle {
		text-transform: uppercase;
	}

	.priceInfo {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		font-size: 12px;
		line-height: 0.9em;
		margin-top: 10px;
		padding-bottom: 6px;

		span#priceAdditionalSave {
			font-weight: normal;
			text-transform: none;
		}

		.pdHighlight {
			margin: 0;
		}

		// hide empty priceInfo block
		&:not(:has(li)) {
			display: none;
		}
	}

	// fix: ipad blue outline for multiproduct select
	.touch & .lpSelectWrapper.closed .multiProdSizeOptions,
	.touch & .lpSelectWrapper.closed .mainSelector {
		outline: 0;
	}
}

#pdGicleeShadeOptionsTitle {
	float: left;
	font-size: 14px;
	font-weight: 500;
	text-transform: uppercase;
}

#pdScrollableTitle {
	text-transform: uppercase;
}

#pdGicleeShadeOptionsMore {
	float: right;
	font-size: 11px;
}

#qlMultiSkuMssg {
	color: #000;
	font-size: 1.4rem;
	font-weight: 500;
}

#pdClearanceOver20 {
	font-size: 1.4rem;
	font-weight: 500;
}

#pdPriceGuarantee,
.priceGuarantee {
	color: #666;
	line-height: 1.6;
	white-space: nowrap;
}

.clearance {
	color: #000;
	font-size: 11px;
	text-transform: uppercase;
}

.dimmerTitle {
	color: #000;
	font-size: 1.4rem;
	font-weight: 500;
}

.tradePrice {
	color: #000;
}

.availInventory {
	color: #900;
	display: block;
	font-size: 14px;
	font-weight: 500;
	margin: 15px 0 0;
	text-transform: uppercase;

	&.soldOut {
		font-size: 16px;
	}
}

.dark {
	color: #333;
	font-weight: 500;
	text-decoration: none;
	text-transform: uppercase;
}

#qlQty {
	float: left;
	font-family: var(--fontHeading);
	line-height: 37px;
	margin: 7px 7px 0 0;

	select,
	input {
		border: 1px solid #999;
		box-sizing: border-box;
		font-size: 12px;
		height: 37px;
		padding: 1px;
		text-align: center;
		vertical-align: top;
		width: 47px;
	}
}

.addToCartContainer {
	@include clearfix;
	margin: 10px 0 15px;

	.calloutBtn {
		letter-spacing: 0;
		padding-left: 0;
		padding-right: 0;
		width: 184px;

		span {
			font-size: 20px;
		}

		&.hidden {
			display: none;
		}

		&.addToPortfolio {
			float: left;
			margin-left: 15px;
			padding: 6px 0;
			width: 97px;

			span {
				font-size: 15px;
				line-height: 18px;
			}
		}
	}
}

#qlSoldOut {
	background: #fff;
	border-color: #c00;
	color: #c00;
	float: left;
	padding: 16px 0 17px;
	width: 184px;

	span {
		font-size: 18px;
	}

	&.hidden {
		display: none;
	}
}

.scrollable {
	overflow: hidden;
	position: relative;

	.items {
		clear: both;
		position: absolute;
		width: 20000em;
	}
}

.browse {
	cursor: pointer;
	display: block;
	float: left;
	height: 80px;
	width: 22px;

	&::before {
		border-top: 2px solid #999;
		content: '';
		display: inline-block;
		height: 12px;
		position: relative;
		top: 32px;
		width: 12px;
	}

	&.left::before {
		border-left: 2px solid #999;
		left: 7px;
		transform: rotate(-45deg);
	}

	&.right::before {
		border-right: 2px solid #999;
		transform: rotate(45deg);
	}

	&:hover::before {
		border-color: #000;
	}

	&.disabled {
		cursor: default;

		&::before {
			border-color: #eee;
		}
	}
}

.pdScrollable {
	padding-top: 10px;

	.scrollable {
		float: left;
		height: 80px;
		overflow: hidden;
		position: relative;
		width: 328px;
	}

	.items {
		clear: both;
		position: absolute;
		width: 20000em;

		> div:nth-child(1) {
			padding-left: 1px;
		}

		> div {
			float: left;
		}
	}

	.pdScrollableThumb {
		border: 1px solid #ccc;
		cursor: pointer;
		float: left;
		height: 76px;
		margin-right: 2px;
		padding: 1px;
		width: 76px;

		&:hover {
			border-color: #000;
		}
	}

	.pdOtherOptionsThumb {
		text-decoration: none;
	}

	strong {
		display: block;
		font-size: 1.4rem;
		font-weight: 500;
		margin: 0 0 10px 20px;
	}
}

.categoryNavContainer {
	border: 1px solid #eee;
	box-sizing: border-box;
	margin-right: 15px;
	padding: 15px 10px 5px;
	width: 210px;

	.categoryNavList {
		line-height: 1;
		list-style-type: none;
		margin: 0;

		li {
			margin-bottom: 10px;
		}

		a {
			text-decoration: none;

			&:hover {
				font-weight: 500;
			}
		}
	}
}

//***************************************** SFP *****************************************//
.sfpclass {
	#qlProdInfoCol {
		#pnlPleaseCallCustomerService {
			margin-top: 15px;

			.chatWrapper {
				padding-bottom: 0;
			}
		}
	}

	#qlProdImg {
		border: 0;
		margin-right: 20px;
		min-height: 200px;
		padding: 0;
		width: 274px;

		@media (min-width: 1280px) {
			margin-right: 40px;
		}
	}

	#staticImage {
		max-height: 270px;

		&.unveil {
			display: block;
			height: 100%;
			margin: 0 auto;
			max-width: 100%;
		}
	}

	.pdInformationDesc {
		font-size: 1.8rem;
	}

	.sfpChatBtn {
		font-size: 1.3rem;
		font-weight: 500;
	}

	#qlProdContent {
		margin-bottom: 0;
		width: 100%;
	}

	.qlSliders {
		display: none;
	}

	#openBoxPlaLinkWrapper {
		display: flex;
		flex-direction: row;
		gap: 5px;
		margin: 12px 0 6px;
	}

	.openboxPlaExplainer {
		&__trigger {
			display: inline-block;
		}

		&__contentHeading {
			font-weight: 500;
		}

		.lpTooltip {
			color: var(--baseTextColor);
			font-weight: normal;
			line-height: 25px;
			text-transform: none;
			width: 430px;

			&__inner {
				padding: 20px;
			}
		}
	}

	#pdAddToCart {
		flex-grow: 0.5;
		float: left;
		margin-left: 7px;
		min-width: 200px;
	}

	.qlViewDetailsBtn {
		height: 50px;
		line-height: 50px;
		padding: 0 1.1em;
	}

	&.sfp2 {
		#qlProdImg {
			width: 350px;
		}

		#staticImage {
			max-height: 350px;
			width: 350px;
		}

		#pdProdInfoCol,
		#qlProdContent {
			width: 570px;
		}
	}

	&.fullPage,
	&.sfp3 {
		.sortMoreYouMayLike {
			border: 0;
			margin: 0;
			padding: 0;

			#certonaItems {
				flex-wrap: wrap;
				justify-content: flex-start;
				margin-top: -2px;
				padding: 0;

				&::after {
					content: '';
					flex: auto;
				}

				a {
					bottom: 16px;
					text-decoration: none;

					span[itemprop] {
						display: block;
						font-size: 0.85em;
						height: 32px;
						margin: 5px 0 2px;
					}

					.sortResultProdPrice {
						float: none;
						margin: 0 16px;
					}

					&:hover {
						color: #333;

						span[itemprop] {
							text-decoration: underline;
						}
					}
				}
			}
		}

		.sortCallout2,
		.sortCallout3,
		.sortCallout4,
		.sortCallout5,
		.sortCallout6 {
			right: 12px;
			z-index: 1;
		}
	}

	&.fullPage {
		.sortResultContainer {
			border: 0;
			margin-bottom: 0;
			padding-bottom: 13px;
		}

		.sortResultImgContainer {
			border: 0;
		}

		.sortMoreYouMayLike {
			#certonaItems {
				.sortResultContainer {
					height: auto;
				}

				.sortResultProdPrice {
					margin-top: 18px;
				}
			}
		}

		#qlBkg {
			border-bottom: 0 !important;
			display: flex;
			padding: 0;
			padding-bottom: 0 !important;

			@media (min-width: 1440px) {
				padding: 0 4rem;
			}

			#pdMoreViews {
				padding-bottom: 0;
			}
		}

		.pdBadges {
			display: none !important;
		}
	}

	.sortMoreYouMayLike #certonaItems {
		.sortResultContainer {
			@include responsiveListItemMargins($sortResultContainerWidth, 3);

			@media (min-width: 1280px) {
				@include responsiveListItemMargins($sortResultContainerWidth, 4);
			}
		}
	}

	.lpcHeader {
		&.lpcToggleCollapsible::after {
			z-index: 0;
		}
	}

	.qlStoreLocation {
		&__text,
		&__csaLink {
			font-size: 1.5rem;
			font-weight: 500;
		}
	}

	.qlStoreLocationInfo {
		&__container {
			display: flex;
			padding: 1rem 1rem 0;
		}

		&__text {
			display: block;
		}

		&__address {
			width: 43%;
		}

		&__contactInfo {
			padding: 1rem 0;
		}

		&__hours {
			padding-top: 2.1rem;
			width: 57%;

			> p {
				margin: 0.5rem 0;
			}

			> ul {
				list-style-type: none;
				margin: 0;
				padding: 0;
			}

			.openNow,
			.closed {
				font-weight: 500;

				~ span {
					font-weight: 500;
				}
			}
		}

		&__time {
			float: right;
		}
	}
}

/***************************************** SFP *****************************************/

#paypalLaterWidget {
	margin: 5px 0 27px;

	// Empty priceInfo list takes up space due to its margin, so let's compensate for it
	.priceInfo:not(:has(li)) + & {
		margin-top: -5px;
	}
}
