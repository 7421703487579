@import '../global/style-guide';

.volumePricing {
	color: #000;
	display: block;
	font-family: var(--fontHeading);
	font-size: 1.1em;
	font-weight: 500;
	text-transform: uppercase;

	&.hidden {
		display: none;
	}
}

#pnlProductPrice {
	margin: 30px 0 0;
}

.pdPleaseCall {
	margin-top: 0;
}

#pnlNotForCanada {
	margin-top: 15px;

	.pdNotForCanada {
		color: $highlightRed;
		font-weight: 500;
		letter-spacing: -0.03em;
	}

	.pdPleaseCall {
		letter-spacing: -0.02em;

		span {
			font-weight: 500;
			text-decoration: none;

			.chatWrapper {
				padding-bottom: 0;
			}
		}
	}
}

#lblPriceType {
	margin-right: 4px;
}

#lblPrice,
.prodPrice {
	color: #000;
	font-family: var(--fontHeading);
	font-size: 3rem;
	font-weight: 500;
	letter-spacing: -0.03em;
	line-height: 30px;

	@media (min-width: 1280px) {
		font-size: 3.6rem;
	}

	.currencyCents,
	.currencySign {
		font-size: 0.6em;
		position: relative;
		top: -0.5em;
		vertical-align: baseline;

		#bdQL & {
			left: -6px;
		}
	}

	.currencySign {
		font-weight: 100;
	}

	#qlProdContent & {
		position: relative;
		top: -2px;
	}
}

#lblFreeShipping,
#lblFreeShippingReturn,
#lblFreeShippingOver50,
#lblCanadaShipping {
	white-space: nowrap;
}

.freeShippingOverMinimum,
#lblFreeShippingOver50,
#lblFreeShippingToStatesWithStoresOver50 {
	cursor: pointer;
}

.freeShippingTooltip {
	display: inline-block;
	width: auto;

	span {
		font-weight: 400;
	}

	&:hover &__trigger {
		z-index: 9002;
	}

	.lpTooltip {
		color: #333;
		font-weight: normal;
		line-height: 25px;
		text-transform: none;
		width: 410px;
		z-index: 10;

		&__inner {
			padding: 20px;
		}
	}
}

.priceInfo {
	color: #000;
	line-height: 18px;

	#pdPriceGuarantee {
		white-space: nowrap;
	}

	span {
		&#ProductPrice_lblPriceType,
		&#priceAdditionalSave,
		&.salePrice > mark {
			background-color: transparent;
			color: inherit;
			font-weight: 500;
			text-transform: uppercase;
		}

		&.saleEnd {
			white-space: nowrap;
		}

		&.currencySign {
			font-size: 0.6em;
			position: relative;
			top: -0.5em;
			vertical-align: baseline;
		}
	}
}

#lblShippingLatency {
	display: inline;
}

.pricingCalloutContainer {
	margin-bottom: 6px;
}

.pdHighlight {
	color: #000;
	display: block;
	flex-basis: 100%;
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 10px;
	text-transform: uppercase;
}

.tradePrice {
	color: #000;
}

.stockCheckTitle {
	font-size: 16px;

	#qlProdContent & {
		font-size: 14px;
	}
}

.stockCheckBody {
	font-size: 12px;
}

.showReturnPolicies {
	font-size: 12px;
}

.shippingWrapperQL {
	display: inline-block;
	margin-left: 15px;

	> div {
		top: 3px;
	}
}

.shipsTodayCutoff {
	font-size: 1.2em;
}

.priceAdditional,
#qlProdContent .pricingCalloutContainer {
	align-items: center;
	display: flex;

	.origPrice,
	.regPrice,
	.pdHighlight,
	.newProductPrice {
		align-items: center;
		display: flex;
	}
}

.strikedPdPriceTooltipWrapper {
	margin: 0 3px 0 5px;
}
