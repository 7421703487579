@import '../global/style-guide.scss';
@import '../global/utilities';

$sortResultContainerWidth: 274px;
$sortResultContainerSearchWidth: 264px;
$sortResultContainerLargeSearchWidth: 320px;
$sortResultContainerWidthLargedevice: 300px;
$sortResultLuxuryLightingContainerWidth: 382px;

html,
#bdProdSort {
	@media (min-width: 960px) {
		overflow-x: hidden;
	}
}

#bdProdSort.isSemiResponsive #lpContainer {
	@include responsiveWidthLayout;
}

%loader {
	content: '\e960';
	font-family: 'lpIcons';
	font-size: 2.5em;
	position: absolute;
	text-align: center;
	top: 48%;
	width: 100%;
	z-index: -1;
}

// No Results for Price Range
#noResults {
	background: #fff4d8;
	border: 2px solid #e5cb90;
	font-size: 1.45rem;
	font-weight: 500;
	margin-top: 5px;
	padding: 10px;
	text-align: center;

	&::before {
		border: 2px solid #e5cb90;
		border-radius: 50%;
		color: #e5cb90;
		content: '!';
		display: inline-block;
		height: 20px;
		margin-right: 5px;
		width: 20px;
	}
}

.sortButtonPillContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: -10px;
}

.sortButtonPill {
	background-color: #f1f1f1;
	border-radius: 25px;
	color: #333;
	margin: 0 5px 10px;
	padding: 14px 30px;
	text-decoration: none;
}

.sortProdSprite {
	background: url('/img/global/sort-prod-sprite.png?v=5') no-repeat;
	display: block;
	overflow: hidden;
}

#lpContainer {
	padding: 15px 0 0;
}

// Certona Collapsible Items
.collapsible {
	clear: both;
	padding-top: 0;
}

#sfpQuicklook {
	margin: 5px auto;
	padding: 20px 0 0;
	position: relative;

	&.sfp2 {
		height: 360px;
	}

	&::before {
		@extend %loader;
	}
}

.sortCol {
	margin-top: 20px;

	&--luxuryLighting {
		margin-top: 10px;
	}

	#lblListingAdMore {
		font-size: 24px;
	}
}

.suggestedResults {
	font-size: 16px;
}

.sortPageH1Tag,
.sortSplashTitleLeft,
.subsequentPageH1 {
	font-size: 28px;
	margin-bottom: 0;

	+ script + span,
	+ span,
	> span {
		display: block;
		font-size: 12px;
		font-weight: 400;
		letter-spacing: 0;

		&.currencySign {
			display: inline;
			font-size: inherit;
			font-weight: inherit;
			text-transform: uppercase;
		}
	}

	+ script + span,
	+ span {
		color: #000;
		margin: 3px 0 12px;
	}
}

.sortSplashLeftMssg {
	float: left;
	font-size: 1.4rem;
	margin: 5px 0 30px;
}

.addSchemeContainer {
	float: right;
	margin: -32px 0 0;

	h1 {
		margin: 0 0 8px;
		text-align: right;
	}
}

.sortFilterText {
	display: block;
	margin: 3px 10px 0 0;
}

.sortMenu {
	display: flex;
	width: 960px;

	.isSemiResponsive & {
		@include responsiveWidthLayout($hasGutter: false);
	}

	.skeleton--dropdownBtn {
		display: inline-block;
		height: 36px;
		margin-right: 9px;
		width: 78px;
	}
}

.SortFilterDisplaySetDropdowns {
	margin: 0 0 24px;
	min-height: 48px;
	position: relative;
	-webkit-tap-highlight-color: transparent;
	z-index: 2;
}

.setDT-1 {
	background: #fff;
	height: fit-content;
	width: 100%;
	z-index: 2;

	&.stickyFilter {
		animation: slideDown 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s 1;
		box-shadow: 0 4px 10px -2px rgba(102, 102, 102, 0.3);
		left: 0;
		padding: 16px 0;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 30;

		.SortFilterDisplaySetDropdowns {
			margin-bottom: 0;

			.isSemiResponsive & {
				margin: 0 auto;
				max-width: 1440px;
				width: calc(100% - #{$responsiveSideGutter * 2});
			}
		}

		// Maximum of 3 rows of buttons in sticky sort menu
		.SortFilterGroupDropdown:nth-child(n + 35) {
			visibility: hidden;
		}

		@media screen and (max-width: 1265px) {
			.SortFilterGroupDropdown:nth-child(n + 31) {
				visibility: hidden;
			}
		}

		@media screen and (max-width: 1149px) {
			.SortFilterGroupDropdown:nth-child(n + 27) {
				visibility: hidden;
			}
		}
	}
}

.freeReturnsContainer {
	display: inline-block;
	width: auto;

	.freeShippingTooltip {
		&:hover &__trigger {
			z-index: 9002;
		}

		.lpTooltip {
			color: #333;
			font-weight: normal;
			line-height: 25px;
			text-transform: none;
			width: 410px;
			z-index: 10;

			&__inner {
				padding: 20px;
			}
		}
	}
}

// Mimic Sticky.js plugin animation.
@keyframes slideDown {
	from {
		transform: translate3d(0, -200%, 0);
	}

	to {
		transform: translate3d(0, 0, 0);
	}
}

.sortNav {
	height: 26px;
	margin-bottom: 18px;

	&.sortNav-clearance {
		color: #000;
		display: block;
		font-size: 12px;
		font-weight: 400;
		letter-spacing: 0;
		margin-bottom: -27px;
	}

	&.bottom {
		.sortFilters {
			margin-bottom: 0;
		}
	}

	.shopByRoom & {
		margin-bottom: 8px;
	}
}

.sortItemsPerPageContainer {
	float: left;
	line-height: 26px;
}

.sortNav.bottom {
	height: 35px;
	margin: 38px 0 16px;

	.sortNavPaging {
		float: none;
		margin-left: 0;
	}

	.sortPageXofXContainer {
		float: none;
		font-weight: normal;
		margin: 0 25px;
		text-transform: none;
	}
}

.sortPageXofXContainer {
	float: left;
}

.sortNavPaging {
	float: right;
	font-weight: 500;
	margin-left: 30px;
	padding: 2px 0;
	text-transform: capitalize;

	a.disabled {
		color: #ccc;

		&.calloutBtn {
			color: #fff;
		}

		&:hover {
			cursor: default;
		}
	}

	.bottom & {
		margin-top: -15px;
		padding: 0;

		.sortNavPagingPrev {
			font-size: 14px;
		}

		.sortNavPagingNext {
			text-transform: uppercase;
		}
	}
}

.contextButtons {
	margin: 40px 0;
	text-align: center;

	a:nth-child(2) {
		margin-left: 16px;
	}
}

.hasLuxuryLightingAttribute {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin: 0 auto;

	@media (min-width: 1354px) {
		max-width: 1276px;
	}
}

.sortResultContainer {
	border-bottom: 1px solid #ccc;
	float: left;
	height: 386px;
	margin: 9px 22px 15px;
	padding-bottom: 30px;
	position: relative;
	width: $sortResultContainerWidth;

	&.sortHospitality {
		min-height: 402px;
	}

	#bdRecentlyViewed &,
	#bdJustForYou & {
		height: 354px;
	}

	&.hideMoreLikeThis {
		height: 358px;

		.sortMoreLikeThisBtn {
			display: none;
		}
	}

	.isSemiResponsive & {
		@include responsiveListItemMargins($sortResultContainerWidth, 3);

		@media (min-width: 1280px) {
			@include responsiveListItemMargins($sortResultContainerWidth, 4);
		}
	}

	.hasLuxuryLightingAttribute & {
		flex: 0 29%;
		height: 100%;
		margin: 9px 20px;
		max-width: $sortResultLuxuryLightingContainerWidth;
		min-height: 386px;
		min-width: $sortResultContainerWidth;

		@media (min-width: 1120px) {
			flex: 0 30%;
			margin: 9px 17px;
		}

		@media (min-width: 1354px) {
			flex: 0 32%;
			margin: 9px 0;

			&:nth-child(3n + 2) {
				margin-left: 65px;
				margin-right: 65px;
			}
		}
	}

	&:hover {
		.sortResultImgContainer {
			border-color: #999;
		}

		.sortResultProdNameInner {
			text-decoration: underline;
		}
	}

	&.pseudoSku {
		height: 386px;
		position: relative;

		img {
			display: block;
			margin: 0 auto;
		}

		&::before {
			@extend %loader;
		}
	}

	&.floorLampContainer {
		height: 448px;
	}

	.sortResultLink {
		text-decoration: none;
	}

	.sortResultProdNameFavIconWrapper {
		display: flex;
		justify-content: space-between;

		.sortResultProdName {
			display: inline-block;
			margin-left: 0;
		}

		.heart {
			align-items: center;
			background-color: #fff;
			border: none;
			cursor: pointer;
			display: flex;
			font-size: 1.5em;
			justify-content: center;
			margin-left: 15px;

			&.disabled {
				cursor: pointer;
				opacity: 0.4;
				pointer-events: none;
			}
		}

		.lpIcon-favoriteselected {
			color: #c00;
		}

		.lpIcon-favorite {
			color: #999;
		}
	}
}

.sortResultImgContainer {
	box-sizing: border-box;
	cursor: pointer;
	margin: auto;
	padding: 5px 0;
	position: relative;
	text-align: center;
	transition: border-color 0.1s;
	width: $sortResultContainerWidth;

	.hasLuxuryLightingAttribute & {
		width: 100%;
	}

	.sortResultProdImg {
		border-radius: 5px;
		display: block;
		min-height: $sortResultContainerWidth;

		.hasLuxuryLightingAttribute & {
			height: 100%;
		}
	}
}

// Sort Item Text Callouts
.sortCallout1,
// 16+ Colors
.sortCallout2,
// Sold Out
.sortCallout3,
// More Options
.sortCallout4,
// Daily Sale
.sortCallout5,
// n Left with Daily Sale
.sortCallout6,
// n Left (alone)
.sortCallout7 {
	align-items: center;
	// 100+ Colors
	background: rgba(241, 241, 241, 0.9);
	color: #444;
	cursor: pointer;
	display: inline-flex;
	font-family: var(--fontHeading);
	font-size: 13px;
	font-weight: 500;
	height: 25px;
	line-height: 1;
	padding: 0 8px;
	position: absolute;
	right: 0;
	text-align: center;
	text-transform: uppercase;
	top: 254px;

	&.floorLamp {
		top: 318px;
	}

	.hasLuxuryLightingAttribute & {
		bottom: 4px;
		top: auto;
	}
}

// Lamps Plus Choice
.sortCallout8 {
	background: #333;
	color: #fff;
	cursor: pointer;
	font-size: 12px;
	left: 0;
	padding: 5px 8px;
	position: absolute;
	text-transform: uppercase;
	top: 5px;
}

.moreLikeThis.hasLuxuryLightingAttribute {
	.sortCallout2,
	.sortCallout3,
	.sortCallout4,
	.sortCallout5,
	.sortCallout6 {
		top: auto;
	}
}

.sortCallout1,
// N+ colors
.sortCallout7 {
	strong {
		display: inline-block;
		font-size: 18px;
		margin-right: 3px;
	}
}

.sortCallout2,
.sortCallout4 {
	background: rgba(0, 0, 0, 0.9);
	color: #fff;
}

// daily sale
.sortCallout4 {
	background-color: #d11919;
}

// qty left adjacent to daily sale badge
.sortCallout5 {
	right: 99px;
}

.sortPortfolioConfirmation {
	background: #fff;
	color: #900;
	display: none;
	font-size: 18px;
	height: 248px;
	left: 0;
	line-height: 20px;
	padding: 100px 10px 0;
	position: absolute;
	text-align: center;
	top: 0;
	width: 312px;
	z-index: 2;
}

.sortPortfolioConfirmation h1 {
	font-size: 1em;
}

.sortResultsProdInfo {
	margin: 0 auto;
	min-height: 35px;

	&--hospitality-item {
		min-height: 26px;
	}

	.maskNumber {
		display: block;
		font-weight: 500;
		margin: 0 0 4px;
		min-height: 21px;
	}

	.schemeNumber {
		color: $highlightRed;
		display: block;
		font-weight: 500;
		margin: -4px 0 4px;
	}

	span.currencySign {
		font-size: 0.7em;
		margin-left: 3px;
		position: relative;
		top: -0.3em;
		vertical-align: baseline;
	}
}

.sortResultProdName {
	color: #333;
	display: block;
	font-size: 12px;
	font-weight: normal;
	height: 44px;
	line-height: 16px;
	margin: 5px 0 7px;
	text-decoration: none;
	transition: text-decoration 0.1s;

	&:visited {
		color: #333;
	}
}

.sortResultProdPrice {
	float: left;
	font-size: 1.5rem;
	font-weight: 500;
	height: 25px;

	a {
		bottom: 5px;
		font-weight: normal;
		position: absolute;
		right: 15px;
	}
}

.salePrice {
	color: var(--darkRed);
}

.specialValue {
	color: #333;
	font-size: 1.2rem;
	font-weight: normal;
}

.sortResultRegPrice {
	display: none; // for A/B testing
}

.sortResultSavePrice,
.sortResultRegPrice {
	float: left;
	font-size: 12px;
	line-height: 20px;
	margin-left: 5px;
	margin-top: 2px;
}

.sortResultRegPrice::before {
	color: #888;
	content: '|';
	margin-right: 5px;

	.sortResultSavePrice.hidden + & {
		display: none; // For AB Testing
	}
}

.sortSaveShipReturns {
	font-size: 10.5px;
	font-weight: 400;
	height: 12px;
	letter-spacing: -0.05em;
	line-height: 1;
	padding-top: 4px;
	text-decoration: underline;
	word-spacing: -0.5px;
}

.sortMoreLikeThisBtn {
	border: 1px solid #999;
	border-radius: 5px;
	color: #565656;
	cursor: pointer;
	float: right;
	font-size: 11px;
	line-height: 18px;
	margin-top: 1px;
	padding: 0 4px;
	text-decoration: none;
	text-transform: uppercase;
	transition: background-color 0.1s ease 0s, color 0.1s ease 0s;

	&:hover {
		background-color: #ddd;
		border-color: #ddd;
		color: #000;
	}
}

#pdClearanceInventoryNotification {
	background: rgba(255, 255, 255, 0.95);
	background: #fff;
	border: 1px solid #000;
	padding: 10px;
	text-align: center;
	width: 320px;
}

.backToTopSfp,
.backToTop {
	@include clearfix;
	margin: 10px auto;
	text-align: center;

	span {
		cursor: pointer;
		font: 500 1em var(--fontHeading);
		line-height: 18px;
		text-transform: uppercase;
	}

	span[class^='lpIcon'] {
		color: #999;
		font-size: 1.5em;
		vertical-align: middle;
	}
}

.backToTopButton {
	background: none;
	border: none;
	color: rgb(51, 51, 51);
	cursor: pointer;
	font: 500 1em var(--fontHeading);
	line-height: 23px;
	padding: 0;
	text-decoration: none;
	text-transform: uppercase;
}

/* COLOR SWATCHES ON PDP HYBRID */
.swatch-wrapper {
	display: block;
}

.swatches {
	display: table;
	margin: 5px auto 0;
}

.swatches li {
	border-radius: 50%;
	color: #000;
	display: inline;
	float: left;
	margin: 0 4px;

	:first-child {
		margin-left: 0;
	}

	:last-child {
		margin-right: 0;
	}
}

.swatches li a {
	display: block;
	height: 56px;
	width: 56px;
}

.swatch {
	display: inline-block;
	position: relative;

	&.black {
		background: #000;
	}

	&.blue {
		background: #006aaf;
	}

	&.brown {
		background: #523c00;
	}

	&.gold {
		background: #d5b85d;
		background: linear-gradient(135deg, #d5b85d 0%, #ecde96 99%);
	}

	&.gray {
		background: #787e81;
	}

	&.green {
		background: #009439;
	}

	&.naturals {
		background: #c2a493;
	}

	&.noColor {
		background: #e5e5e5;
		background: linear-gradient(135deg, #e5e5e5 1%, #fff 100%);
	}

	&.orange {
		background: #f96b29;
	}

	&.pink {
		background: #dd6791;
	}

	&.purple {
		background: #704981;
	}

	&.red {
		background: #ae3130;
	}

	&.white {
		background: #fff;
	}

	&.yellow {
		background: #f8d316;
	}
}

.swatch span {
	display: none;
	white-space: nowrap;
}

.swatch:hover span {
	background: #eee;
	border: 1px solid #000;
	color: #000;
	display: inline-block;
	font-size: 12px;
	font-weight: normal;
	left: 50%;
	padding: 2px 4px;
	position: absolute;
	text-align: center;
	top: 45px;
	transform: translateX(-50%);
	z-index: 1;
}

.sortLandingDescription2 {
	border-top: 1px solid var(--baseBorderColor);
	font-family: var(--fontHeading);
	margin: 30px 0 0;
	overflow: hidden;
	padding: 15px 36px 0;

	a {
		text-decoration: underline;
	}

	.sortLandingDescription2__image-wrapper {
		float: right;
		margin-bottom: 1rem;
		margin-left: 1rem;
		text-align: center;

		em,
		img {
			display: block;
		}
	}

	.sortLandingMobile {
		display: none;
	}

	.lpLandVideo {
		float: right;
		margin-bottom: 20px;
		margin-left: 20px;
	}
}

#sortResultProducts {
	.productsGroupHeader {
		clear: both;
		padding-top: 40px;
		width: 100%;
	}
}

//***************************************** SFP *****************************************//
.sfpclass {
	#qlProdInfoCol {
		#pnlPleaseCallCustomerService {
			margin-top: 15px;

			.chatWrapper {
				padding-bottom: 0;
			}
		}
	}

	#qlProdImg {
		border: 0;
		margin-right: 20px;
		min-height: 200px;
		padding: 0;
		width: 236px;
	}

	#staticImage {
		max-height: 236px;
	}

	#qlProdContent {
		float: left;
		margin-bottom: 0;
	}

	.qlSliders {
		display: none;
	}

	&.sfp2 {
		#qlProdImg {
			width: 350px;
		}

		#staticImage {
			max-height: 350px;
			width: 350px;
		}

		#pdProdInfoCol,
		#qlProdContent {
			width: 570px;
		}
	}

	&.fullPage {
		.sortMoreYouMayLike {
			border: 0;
			margin: 0;
			padding: 0;

			.sortSkeletonItem {
				@include responsiveListItemMargins($sortResultContainerWidth, 3);
				margin-bottom: 1rem;
				width: $sortResultContainerWidth;

				.imageWrapper {
					display: block;
					min-height: $sortResultContainerWidth;
				}

				@media (min-width: 1280px) {
					@include responsiveListItemMargins($sortResultContainerWidth, 4);
				}
			}

			#certonaItems {
				flex-wrap: wrap;
				justify-content: flex-start;
				margin-top: 19px;
				padding: 0;

				&::after {
					content: '';
					flex: auto;
				}

				a {
					bottom: 33px;
					right: 0;
					text-decoration: none;

					span[itemprop] {
						display: block;
						font-size: 0.85em;
						height: 32px;
						margin: 5px 0 2px;
					}

					&.sortMoreLikeThisBtn {
						border-radius: 5px;
						line-height: 24px;
					}

					.sortResultProdPrice {
						float: none;
						margin: 0 16px;
					}

					&:hover {
						color: #333;

						span[itemprop] {
							text-decoration: underline;
						}
					}
				}
			}
		}

		.sortCallout2,
		.sortCallout3,
		.sortCallout4,
		.sortCallout5,
		.sortCallout6 {
			right: 12px;
			z-index: 1;
		}

		.suggestedProductsContainer {
			border-top: 1px solid #eee;
			padding-bottom: 30px;
			padding-top: 10px;

			.sortCallout2,
			.sortCallout3,
			.sortCallout6 {
				bottom: 0;
				right: 0;
			}

			.sortCallout3 {
				width: auto;
			}

			.sortCallout4 {
				right: 1px;
				top: 249px;
			}

			.sortCallout5 {
				right: 1px;
				top: 223px;
			}
		}

		#qlBkg {
			border-bottom: 1px solid #eee;
			margin: 0 auto;
			padding-bottom: 30px;
		}
	}

	.tradePriceLabel {
		font-weight: 500;
		margin-bottom: 0.4rem;
	}
}

// static luxury lighting banner
#sortHybridLuxuryLightingBanner {
	margin: 0 0 15px 3px;

	.sortSplashContainer & {
		margin-top: 10px;
	}

	.sortPageH1Tag {
		color: #000;
		font-family: 'ChronicleDeckRoman', Baskerville, Palatino, 'Times New Roman', serif;
		font-size: 3rem;
		font-weight: 400;
		letter-spacing: 1px;
		margin-bottom: 0;
		text-transform: uppercase;
	}

	.sortPageH1Tag.sortOutdoorLightingH1 {
		display: inline-block;
	}

	.sortHybridLuxuryLightingTitle {
		display: block;
		font-size: 1.8rem;
		letter-spacing: -0.5px;
		text-transform: uppercase;
	}

	.result {
		font-size: 1.1rem;
		letter-spacing: 0.3px;
		line-height: 2.2;
		margin-top: 0;
		text-transform: uppercase;
	}

	// reset font for LL title and result count
	.sortHybridLuxuryLightingTitle,
	.result {
		font-family: var(--fontHeading);
	}
}

//Dynamic luxury lighting banner
#sortHybridLuxuryLighting {
	--sortPageH1Width: 260px;
	align-items: center;
	display: grid;
	grid-template-areas:
		'luxuryTitle luxuryBucket luxuryBucket'
		'luxuryResult luxuryBucket luxuryBucket';
	grid-template-columns: 1fr 4fr;
	padding: 1px 0 6px 3px;
	width: 100%;

	.sortPageH1Tag {
		color: #000;
		font-family: 'ChronicleDeckRoman', Baskerville, Palatino, 'Times New Roman', serif;
		font-size: 3rem;
		font-weight: 400;
		grid-area: luxuryTitle;
		letter-spacing: 1px;
		margin-bottom: 0;
		margin-top: auto;
		text-transform: uppercase;
		width: var(--sortPageH1Width);
	}

	.sortHybridLuxuryLightingTitle {
		display: block;
		font-size: 1.8rem;
		letter-spacing: -0.5px;
		text-transform: uppercase;
	}

	.sortHybridLuxuryLightingBucket {
		align-content: stretch;
		display: flex;
		grid-area: luxuryBucket;
		justify-content: space-around;
		margin: 0;
		width: 100%;
	}

	.sortHybridLuxuryLightingBucket > li {
		box-sizing: border-box;
		display: inline-block;
		text-align: center;
		vertical-align: top;
		width: 20%;
	}

	li:first-child:nth-last-child(4),
	li:first-child:nth-last-child(4) ~ li {
		width: 25%;
	}

	.sortHybridLuxuryLightingAnchor {
		display: block;
		text-decoration: none;

		img {
			height: 128px;
			width: 128px;
		}
	}

	.sortHybridLuxuryLightingBucketText {
		color: #000;
		text-align: center;
	}

	.result {
		font-size: 1.1rem;
		grid-area: luxuryResult;
		letter-spacing: 0.3px;
		line-height: 1;
		margin-bottom: auto;
		margin-top: 16px;
		text-transform: uppercase;
	}

	// reset font for LL title and result count
	.sortHybridLuxuryLightingTitle,
	.result {
		font-family: var(--fontHeading);
	}
}

//Dynamic luxury lighting banner Latest Design
#sortHybridLuxuryLightingBucketBanner {
	align-items: center;
	background: #000;
	display: flex;
	float: left;
	margin-bottom: 15px;
	padding: 0 0 0 4rem;
	width: fill-available;

	.sortPageH1Tag {
		color: #fff;
		float: left;
		font-family: 'ChronicleDeckRoman', Baskerville, Palatino, 'Times New Roman', serif;
		font-size: 3rem;
		font-weight: 600;
		letter-spacing: 0;
		margin-bottom: 0;
		text-transform: uppercase;
		width: 280px;
		word-spacing: 0.1em;
	}

	.sortHybridLuxuryLightingHybridBucket {
		float: right;
		width: 100%;
	}

	.sortHybridLuxuryLightingTitle {
		display: block;
		font-size: 1.8rem;
		margin-bottom: 0.75rem;
		text-transform: uppercase;
	}

	.sortHybridLuxuryLightingBucket {
		float: right;
		margin: 0;
		padding: 20px 0;
	}

	.sortHybridLuxuryLightingBucket > li {
		box-sizing: border-box;
		display: inline-block;
		padding: 0 10px;
		text-align: center;
	}

	.sortHybridLuxuryLightingAnchor {
		text-decoration: none;
	}

	.sortHybridLuxuryLightingBucketText {
		color: #fff;
		text-align: center;
	}

	.result {
		font-size: 1.1rem;
		margin-bottom: 0;
	}

	// reset font for LL title and result count
	.sortHybridLuxuryLightingTitle,
	.result {
		font-family: var(--fontHeading) !important;
		word-spacing: initial !important;
	}
}

// only styling breadcrumbs on sort page
// TODO once breadcrumbs styles are sitewide this should be in a more general place
.sortBreadCrumbWrapper {
	float: none !important;

	// excludes shop by room, remove :not when these styles are sitewide
	.sortBreadCrumb {
		font-size: 1.3rem;
		height: auto;
		line-height: 2.3rem;
		padding: 8px 0;

		a,
		a:visited,
		a:active,
		span {
			color: #000;
		}

		.breadCrumbValueWrapper {
			white-space: normal;
		}

		.breadcrumbRemoveLevel {
			font-size: 1.15em;
			position: relative;
			top: 2px;
		}
	}

	.showMoreBreadCrumbsBtn {
		background: transparent;
		border: none;
		font-size: 1.3rem;
		margin-left: 8px;

		&__text-wrapper {
			text-decoration: underline;
		}
	}

	&.showHotelBrand {
		max-width: calc(100% - 200px);
	}
}

.availableInStoreWrapper {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	margin: 25px 0 6px;

	.lpForm .field {
		padding-bottom: 0;

		label {
			font-size: 1.2em;
			font-weight: 500;
			text-transform: capitalize;
		}
	}
}

.shopAllCategoryLink,
.shopOpenBoxCategoryLink {
	align-items: center;
	display: inline-flex;

	.arw {
		border-bottom: 5px solid transparent;
		border-left: 5px solid #fff;
		border-right: 5px solid transparent;
		border-top: 5px solid transparent;
		display: inline-block;
		height: 0;
		margin-left: 5px;
		text-indent: -9999px;
		width: 0;
	}
}

.shopOpenBoxCategoryLink {
	.arw {
		border-bottom-color: transparent;
		border-left-color: #555;
		border-right-color: transparent;
		border-top-color: transparent;
	}
}

.splashTitle {
	display: block;
	font-family: var(--fontSerif);
	font-size: 2em;
	font-weight: 400;
	letter-spacing: 0;
	margin: 0 0 10px;
}

.subTitle,
.sortLandingDescription2 h3 {
	font-family: var(--fontSerif);
	font-size: 1.5em;
	font-weight: 400;
	letter-spacing: 0;
}

.splashDesc {
	margin: 0 0 20px;
}

.sortLandingMobile {
	display: none;
}

#contextualSearchBar {
	background-color: #f1f1f1;
	margin: 35px 0 0;
	width: 100%;

	.seachBoxSort {
		align-items: center;
		column-gap: 4rem;
		display: flex;
		justify-content: center;
		margin: 0 21px 10px;
		padding: 20px 0;

		@media (max-width: 1090px) {
			column-gap: 2rem;
		}

		.searchContainer {
			margin: 1px 0 0 20px;

			.searchInputResponsive {
				background: #fff;

				@media (max-width: 1024px) {
					width: 287px;
				}
			}
		}

		.searchArrowBtn {
			background: #fff;

			&::before {
				top: 11px;
			}
		}

		p {
			color: #000;
			font-family: var(--fontHeading);
			font-size: 18px;
			font-weight: 500;
			margin: 0;
		}

		span {
			font-weight: normal;
			padding-left: 6px;
		}
	}
}

// sort/serach template specific CSS
// exclude luxury lighting
.sortCol:not(.sortCol--luxuryLighting, .shopByRoom) {
	#sortResultProducts {
		column-gap: 46px;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	.sortResultContainer {
		border-bottom: none;
		float: unset;
		height: unset;
		margin-bottom: 6px;
		padding-bottom: 35px;
		width: calc((100% - 140px) / 4);

		@media (max-width: 1279px) {
			width: calc((100% - 92px) / 3);
		}

		.isSemiResponsive & {
			margin-left: 0;
			margin-right: 0;
		}

		&.pseudoSku {
			height: 355px;

			img {
				min-height: 264px;
			}
		}

		.sortResultProdNameFavIconWrapper {
			.sortResultProdName {
				// stylelint-disable
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				box-orient: vertical;
				display: -webkit-box;
				display: box;
				line-clamp: 2;
				overflow: hidden;
				// stylelint-enable

				.sortResultProdIsNew {
					background: #000;
					border-radius: 5px;
					color: #fff;
					font-family: var(--fontSerif);
					margin-right: 2px;
					padding: 1px 5px;
				}
			}
		}
	}

	.sortResultImgContainer {
		border-radius: 5px;
		overflow: hidden;
		padding: 0;
		width: $sortResultContainerSearchWidth;
		width: 100%;

		.sortResultProdImg {
			min-height: $sortResultContainerSearchWidth;
			width: 100%;
		}
	}

	// Sort Item Text Callouts
	.sortCallout1,
	// 16+ Colors
	.sortCallout2,
	// Sold Out
	.sortCallout3,
	// More Options
	.sortCallout4,
	// Daily Sale
	.sortCallout5,
	// n Left with Daily Sale
	.sortCallout6,
	// n Left (alone)
	.sortCallout7 {
		bottom: 0;
		top: unset;
	}

	.sortCallout8 {
		top: 0;
	}

	.sortResultProdName {
		height: 32px;
		margin: 10px 0 4px;
	}

	.sortSaveShipReturns {
		padding-top: 2px;
	}

	.sortMoreLikeThisBtn {
		border-radius: 5px;
		line-height: 24px;
		margin-top: -6px;
	}
}

.viewOpenBoxBtnBlock {
	text-align: center;

	.calloutBtn {
		border-radius: 4px;
		box-sizing: border-box;
		height: 40px;
		margin-top: 20px;
		padding-left: 50px;
		padding-top: 10px;

		img {
			height: 30px;
			left: 15px;
			position: absolute;
			top: 5px;
			width: 25px;
		}
	}
}

.wishlistMessage {
	align-items: center;
	animation: wishListMessage 2500ms ease-in-out 200ms 1;
	background-color: rgba(255, 255, 255, 0.8);
	border: 54px solid transparent;
	box-sizing: border-box;
	color: #000;
	display: flex;
	font-size: 2.3rem;
	font-weight: 500;
	height: 100%;
	justify-content: center;
	left: 0;
	line-height: 1.2;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	top: 0;
	width: 100%;
	word-spacing: 0.75px;

	&.red {
		color: $highlightRed;
	}
}

@keyframes wishListMessage {
	0% {
		opacity: 0;
	}

	15% {
		opacity: 1;
	}

	80% {
		opacity: 1;
	}

	98% {
		opacity: 0;
	}
}

.suggestedProductsContainer {
	&.sortMoreYouMayLike {
		margin: 20px 0 0;
		overflow: unset;
		padding: 14px 0 0;
		position: relative;

		.jsCertonaTitle {
			font-size: 2.2rem;
			margin: 0 0 10px;

			&.serif {
				font-size: 2.4rem;
			}

			@media screen and (max-width: 1279px) {
				font-size: 2rem;
				margin: 0 0 25px;

				&.serif {
					font-size: 2.2rem;
				}
			}
		}

		#moreYouMayLikeContainer {
			display: block;
			height: unset;
			margin: 0 -10px;
			padding: 0;

			.moreYouLikeLink {
				align-items: flex-start;
				display: flex;
				flex-direction: column;
				padding: 3px;

				img {
					border-radius: 5px;
				}

				.moreYouLikeImageWrapper {
					width: 100%;
				}

				.moreYouLikebadgPosition {
					height: unset;
					width: unset;

					.moreYouLikeBadgeWrapper {
						position: relative;
					}
				}
			}
		}

		.moreYouLikeProductsName {
			font-size: 12px;
			font-weight: normal;
			line-height: 16px;
			text-align: left;
			word-break: break-all;

			span {
				box-orient: vertical;
				display: box;
				height: 32px;
				-webkit-line-clamp: 2;
				overflow: hidden;
				text-overflow: ellipsis;
				word-break: break-word;
			}
		}

		.moreLikePrice {
			font-size: 14px;
			margin: 5px 0;
			text-align: left;
			width: 100%;

			&.salePrice {
				color: var(--darkRed);
			}
		}

		a:hover {
			.productSummaryRating__reviewCount {
				color: #333;
			}
		}

		.pdSectionTitle {
			margin-bottom: 10px;
		}

		.hideVisibility {
			visibility: hidden;
		}

		.glider-slide {
			padding: 7px;
		}

		.goldReviewStars__star {
			height: 1.6rem;
			margin-right: 2px;
			width: 1.6rem;
		}
	}
}

div#pdMoreYouMayLike + .sortLandingDescription2 {
	margin-top: 16px;
}

.domesticSalesBanner {
	margin-bottom: 30px;

	.domesticSalesBannerMobileImg {
		display: none;
	}
}

#lblStandardFooter,
#lblProFooter {
	.recentlyViewedWrapper.loaded {
		h2.serif {
			font-family: var(--fontSerif);
			font-size: 2.2rem;
			font-weight: 400;
			letter-spacing: 0;
		}
	}
}

.sortGlider {
	position: relative;

	&__container {
		align-items: flex-start;
		display: flex;
		justify-content: flex-start;
		margin: 0 -10px !important;
		overflow: hidden;
	}

	&__item {
		box-sizing: border-box;
		margin: 5px 10px;
		text-decoration: none;

		.glider-loading & {
			flex: 0 0 calc(20% - 20px);
		}

		figure {
			margin: 0;
		}

		figcaption {
			font-size: 1.6rem;
			margin-top: 10px;
		}
	}

	.glider-buttons-inline {
		display: flex;
		top: -30px;

		&:has(~ .glider-loading) {
			display: none;
		}
	}

	&--noHeader {
		padding-top: 20px;

		.glider-buttons-inline {
			top: -5px;
		}
	}
}

.colorPlusSort {
    &__swatches {
        display: flex;
        gap: 10px;
        justify-content: space-between;

		@media (min-width: 1200px) {
			gap: 20px;
		}
    }
  
    &__swatch {
		align-items: center;
        display: flex;
		flex: 1;
		flex-direction: column;
        text-decoration: none;

        &Color {
			aspect-ratio: 1;
            border-radius: 5px;
            width: 100%;
        }

        &--blue &Color {
            background-color: #0069aa;
        }

        &--brown &Color {
            background-color: #4b3932;
        }

        &--green &Color {
            background-color: #309144;
        }

        &--grayBlack &Color {
            background: linear-gradient(to bottom, #a2a5a7, #000);
        }

        &--naturals &Color {
            background-color: #bfa393;
        }

        &--orange &Color {
            background-color: #e8723c;
        }

        &--pink &Color {
            background-color: #dc6690;
        }

        &--purple &Color {
            background-color: #694c7e;
        }

        &--red &Color {
            background-color: #af3130;
        }

        &--whiteClear &Color {
            background-color: #fff;
			border: 1px solid #ccc;
        }

        &--yellow &Color {
            background-color: #f3d24c;
        }
    }

    &__label {
		color: #333;
		font-size: 1.2rem;
        margin-top: 5px;
        text-align: center;
    }
}
